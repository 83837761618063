.home-container{
    width: 70%;
    max-width: 1024px;
    margin: 50px auto 0;
}

.hero-container{
    width: 100%;
    margin-bottom: calc(var(--spacing-para)*2);
}

.hero-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: calc(var(--spacing-para)*3);
}
.hero-title{
    font-size: 32px;
    text-align: left;
    margin-bottom: var(--spacing-para);
    animation: heroTitle .5s .5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes heroTitle {
    from{
        transform: translateY(-100px);
    }
    to{
        transform: translateY(0px);
        opacity: 1; 
    }
}


.hero-descr{
    color: var(--dark-grey);
    text-align: left;
    margin-bottom: var(--spacing-para);
    animation: heroDescr .5s .5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes heroDescr {
    from{
        transform: translateY(100px);
    }
    to{
        transform: translateY(0px);
        opacity: 1; 
    }
}

.button{
    border: 1px solid var(--dark-secondary);
    padding: 8px 16px;
    text-decoration: none;
    font-weight: 500;
}

.btn-contact span{
    letter-spacing: 1px;
}
.btn-contact{
    display: inline-block;
    animation: btnContact .3s .7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes btnContact {
    from{
        transform: scale(0);
        opacity: 1;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

.hero-images{
    position: relative;
    height: 30vw;
    max-height: 400px;
    border-bottom: 1px solid var(--dark-secondary);
    text-align: center;
    animation: heroImages .3s 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes heroImages {
    from{
        transform: translate(200px);
        opacity: 0;
    }
    to{
        transform: translate(0px);
        opacity: 1;
    }
}


.hero-malik{
    z-index: 2;
    position: relative;
    height: 30vw;
    max-height: 400px;
}

.hero-logo{
    position: absolute;
    left: 0;
    z-index: 1;
    height: 90%;
}

.hero-dots{
    position: absolute;
    top: 10%;
    right: 0%;
    z-index: 3;
    height: calc(30vw / 3);
    max-height: 100px;
}

.to-work{
    display: inline-flex;
    bottom: -36px;
    position: absolute;
    align-items: center;
    gap: 5px;
    left: 50%;
    width: max-content;
    transform: translate(-50%);
    padding: 5px 8px;
    border: 1px solid var(--dark-grey);
}

.to-work span{
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    background-color: rgba(255, 0, 0, 0.226);
    border: 1px solid var(--dark-secondary);
}

.citation-container{
    position: relative;
    width: 100%;
    margin: 0 auto;
    animation: animationCitation 1s 1s forwards;
    transform: translateX(10%);
    opacity: 0;
}

@keyframes animationCitation {
    from{
        transform: translateX(10%);
    }
    to{
        transform: translateX(0%);
        opacity: 1;
    }
}

.citation{
    padding: 30px;
    border: 1px solid var(--dark-grey);
    /* width: max-content; */
    font-size: 24px;
    /* text-align: center; */
}

.citation::after{
    display: block;
    position: absolute;
    content: "- Arthur C. Clarke";
    border: 1px solid var(--dark-grey);
    padding: 10px 15px;
    font-weight: 400;
    font-size: 18px;
    color: var(--dark-grey);
    bottom: -45px;
    right: 0;
}



.entrecote{
    display: inline-block;
    font-size: 64px;
    font-weight: bold;
    color: var(--dark-grey);
    background-color: var(--dark-primary);
    height:40px;
    width: 40px;
    border-radius: 9999px;
    text-align: center;
    position: absolute;
}

.entre1{
    top: -25px;
    left: 15px;
}

.entre2{
    bottom: -15px;
    right: 15px;
}

@media screen and (max-width: 1078px) {
    .hero-images{
        height: 30vw;
    }
    .hero-malik{
        height: 30vw;
    }
}

@media screen and (max-width: 900px) {

    .btn-contact{
        display: inline-block;
        margin: 0 auto;
        display: block;
        text-align: center;
        padding: 25px 0px;
    }

    .hero-title{
        font-size: 30px;
        text-align: center;
    }
    .home-container{
        margin-top: 65px;
    }
    .hero-flex{
        flex-direction: column;
        gap: calc(var(--spacing-para)*2);
    }

    .hero-images{
        height: auto;
        width: 70%;
    }

    .hero-malik{
        height: auto;
        width: 100%;
    }
    .to-work{

        font-size: 16px;
    }
}

.section-container{
    animation: sectionAnimation .5s 1.5s forwards;
    opacity: 0;
}

@keyframes sectionAnimation {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.section-container{
    margin: var(--spacing-para) 0;
}

.card-project-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 25px;
    /* grid-row-gap: 0px; */
}



.title-section-container{
    display: flex;
    justify-content: space-between;
}

.title-section{
    font-size: 32px;
    margin-bottom: var(--spacing-para);
}
.title-section::before{
    content: "#";
    color: var(--dark-secondary);
}

.title-section::after{
    content: "";
    color: var(--dark-secondary);
    display: block;
    width: 200px;
    height: 3px;
    background-color: var(--dark-secondary);
    margin-left: 10px;
}

.skills{
    position: relative;
}

.skills-flex{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 35px;
    width: min-content;
    margin-left: 50%;
}
.skills-bloc{
    
}

.decoration img{
    position: absolute;
}

.decoration img:nth-child(1){
    right: 5%;
}

.decoration img:nth-child(2){
    top: 20%;
    width: 100px;
}

.decoration img:nth-child(3){
    bottom: 25%;
    width: 150px;
}

.decoration img:nth-child(4){
    bottom: 50%;
    left: 20%;
    width: 200px;
}

@media screen and (max-width:780px) {
    .skills-flex{
        margin: 0 auto;
    }
    .decoration img{
        display: none;
    }
}

.skills-bloc-title{
    padding: 15px;
    border: 1px solid var(--dark-grey);
    font-weight: 600;
}

.skills-bloc-list{
    border: 1px solid var(--dark-grey);
    border-top: none;
    padding: 15px;
    
}

.skills-bloc-list li{
    list-style: none;
    color: var(--dark-grey);
}


.about-container{
    display: flex;
    align-items: center;
}

.about-text{
    width: 70%;
    color: var(--dark-grey);
    margin-right: 25px;
}

.about-text p{
    color: var(--dark-grey);
}

@media screen and (max-width:900px) {
    .about-container{
        flex-direction: column;
    }

    .about-container .button{
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: var(--spacing-para);
    }
}


.container-contact{
    display: flex;
    align-items: center;
}

.contact-link{
    border: 1px solid var(--dark-grey);
    padding: 15px;
    margin-left: 25px;
}

.contact-title{
    font-weight: 600;
}

.contact-div-link{
    display: flex;
    align-items: center;
}

.contact-div-link img{
    margin-right: 5px;
}

@media screen and (max-width:900px) {
    .container-contact{
        flex-direction: column;
        text-align: center;
    }
    .container-contact p {
        margin-bottom: var(--spacing-para);
    }
    .contact-div-link{
        align-items: center;
        flex-direction: column;
    }
}